.main {
  background-color: #313c71;
  padding: 30px;
  .logoDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px;
      border-radius: 6px;
      img {
        width: 180px;
      }
    }
    .right {
      p {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: #fff;
      }
      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 7px 0px;
        .icon {
          width: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    padding: 60px 0px;
    .addressDiv {
      width: 500px;
      background-color: #313c71;
      font-family: Urbanist;
      .addTitle {
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .add {
        width: 320px;
        color: rgba(255, 255, 255, 0.8);
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 5px;
      }
      .dirTitle {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      .time {
        color: rgba(255, 255, 255, 0.8);
        font-size: 20px;
        margin-bottom: 20px;
      }
      .contactDetail {
        display: flex;
        padding: 5px 0px;
        gap: 40px;
        .contactDiv {
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 10px;
          .contactImg {
            width: 35px;
          }
          .contactText {
            p {
              color: rgba(255, 255, 255, 0.8);
              font-size: 18px;
              font-weight: 300;
            }
            p:nth-child(2) {
              font-weight: 400;
              text-wrap: nowrap;
            }
          }
        }
      }
    }
    .listDiv {
      width: 400px;
      font-family: Urbanist;
      .list {
        color: #fff;
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        padding: 10px 50px;
      }
    }
    .mobileFooter {
      display: none;
    }
    .subscribeDiv {
      border-radius: 8px;
      background: #22354f33;
      padding: 30px 43px;
      .text {
        text-align: center;
        color: #fff;
        .title {
          width: fit-content;
          padding: 0px 10px 7px 10px;
          margin: 0px auto;

          h1 {
            text-align: center;
            padding: 0px 20px;
            font-family: Urbanist;
            font-size: 38px;
            font-weight: 700;
            letter-spacing: 0.76px;
          }
          .gradient {
            margin: 10px 0px;
            width: 100%;
            height: 5px;
            background: linear-gradient(
              90deg,
              #e31e24 -2.39%,
              rgba(49, 60, 113, 0) 83.32%
            );
            border-radius: 5px;
          }
        }
        p {
          font-family: Urbanist;
          font-size: 20px;
          letter-spacing: 0.4px;
        }
      }

      .inputDiv {
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 5px;
        border: 0.5px solid rgba(49, 60, 113, 0.6);
        padding: 10px 7px;
        width: 325px;
        background-color: #fff;
        margin: 20px auto;

        .inputIcon {
          width: 25px;
        }
        input {
          border: none;
          background-color: transparent;
          outline: none;
          font-family: Urbanist;
          font-size: 18px;
          color: #081c4380;
          width: 100%;
          resize: none;
        }
      }

      .sbtDiv {
        background-color: #fff;
        width: 325px;
        margin: 10px auto;
        padding: 10px 7px;
        border-radius: 5px;

        button {
          border-radius: 5px;
          width: 100%;
          border: none;
          padding: 13px 0px;
          font-family: Urbanist;
          font-size: 18px;
          cursor: pointer;
          color: #fff;
          background: #313c71;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0px;
    .logoDiv {
      display: none;
    }
    .content {
      padding: 0px 0px;
      flex-direction: column;
      .addressDiv {
        display: none;
      }
      .listDiv {
        display: none;
      }
      .mobileFooter {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px;
        .singleDetail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            gap: 15px;
            align-items: center;
            img {
              width: 45px;
            }
            p {
              color: white;
              font-size: 20px;
              font-family: Lato;
              font-weight: 600;
            }
          }
          .right {
            width: 25px;
          }
          .rotate {
            transform: rotate(180deg);
            width: 25px;
          }
        }
        .detail {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          font-family: Lato;
          border-bottom: 1px solid #232c5a;
          padding: 10px 0px;

          p {
            padding: 4px 0px;
          }
        }
        .followUs {
          color: #fff;
          font-family: Lato;
          font-size: 18px;
          text-align: center;
        }
        .mobileIconsDiv {
          display: flex;
          gap: 20px;
          justify-content: center;
          img {
            width: 45px;
          }
        }
      }
      .subscribeDiv {
        padding: 30px;
        .inputDiv {
          width: 300px;
        }
        .sbtDiv {
          width: 300px;
        }
      }
    }
  }
}
