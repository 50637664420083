.main{
    padding: 50px 0px;
    .text {
        width: 800px;
        margin: auto;
        text-align: center;
    
        .title {
          width: fit-content;
          padding: 0px 10px 7px 10px;
          margin: auto;
    
          h1 {
            color: #303030;
            text-align: center;
            font-family: Urbanist;
            font-size: 38px;
            font-weight: 700;
            letter-spacing: 0.76px;
          }
          .gradient {
            margin: 10px 0px;
            width: 100%;
            height: 5px;
            background: linear-gradient(
              90deg,
              #000000 -2.39%,
              #313c71 -2.38%,
              rgba(227, 30, 36, 0) 83.32%
            );
            border-radius: 5px;
          }
        }
        .para {
            font-family: Urbanist;
            font-size: 20px;
            line-height: 30px; 
            letter-spacing: 0.4px;
            margin-top: 3%;
        }
      }
}

// @media (min-width: 769px) {
//   .main{
//     padding: 50px 10px;
//     .text{
//       width: 100%;
//     }
//   }
// }

@media screen and (max-width:768px) {
  .main{
    padding: 50px 20px;
    .text{
      width: auto;
      .title{
        h1{
          font-size: 24px;
        }
      }
      .para{
        font-size: 18px;
      }
    }
  }
}