.main {
  padding: 20px 30px;
  .text {
    margin: auto;
    .title {
      width: fit-content;
      margin: auto;

      h1 {
        color: #303030;
        font-family: Urbanist;
        font-size: 30px;
        letter-spacing: 0.76px;
        padding: 0px 25px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #000000 -39.39%,
          #313c71e0 13.62%,
          #f3707466 55%,
          rgba(227, 30, 36, 0) 86.32%
        );
        border-radius: 5px;
      }
    }
  }
  .backDiv {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    width: fit-content;
    img {
      width: 20px;
    }
    p {
      color: #303030;
      font-family: Urbanist;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0.52px;
    }
  }
}
.mobileNav{
  display: none;
}



@media only screen and (max-width: 768px) {
  .mainUp{
    overflow: hidden;
    .main {
      .text {
        .title {
          h1 {
            font-size: 28px;
          }
        }
      }
      .backDiv {
        margin-top: 10%;
        img {
          width: 17px;
        }
        p {
          font-size: 22px;
        }
      }
    }
  }
 
  .mobileNav {
    display: block;
    position: relative;
  
    .navClose {
      left: -999px !important;
    }
    .navContent,
    .navClose {
      font-family: Urbanist;
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0px;
      top: 0;
      background: linear-gradient(#e31e24 0%, #9f151d 117.09%);
      transition: 1s ease;
      .closeDiv {
        padding: 10px 20px;
        img {
          width: 50px;
        }
      }
      .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        height: 80%;
        p {
          font-size: 22px;
          color: #fff;
          cursor: pointer;
          font-family: Urbanist;
          font-weight: 500;
          letter-spacing: 1.8px;
        }
      }
    }
  }
}
