.whywe {
  padding: 50px;
  .text {
    width: 800px;
    margin: auto;
    margin-bottom: 5%;
    text-align: center;

    .title {
      // border-bottom: 6px solid green;
      width: fit-content;
      padding: 0px 10px 7px 10px;
      margin: 20px auto;

      h1 {
        color: #303030;
        text-align: center;
        font-family: Urbanist;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.76px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #000000 -2.39%,
          #313c71 -2.38%,
          rgba(227, 30, 36, 0) 83.32%
        );
        border-radius: 5px;
      }
    }
    .para {
      color: #303030;
      text-align: center;
      font-family: Urbanist;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.4px;
    }
  }
  .cardsDiv {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;

    .card1 {
      border-radius: 80px 0px;
      background: rgba(219, 234, 255, 0.5);
    }
    .card2 {
      border-radius: 8px;
      background: rgba(204, 255, 255, 0.5);
    }
    .card3 {
      border-radius: 0px 80px;
      background: rgba(255, 200, 203, 0.4);
    }

    .singleCard {
      height: 270px;
      width: 290px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      align-items: flex-start;
      gap: 10px;

      img {
        width: 45px;
        margin: 0px auto;
      }
      .abtCard {
        font-family: "Urbanist", sans-serif;
        margin: 0px auto;
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.44px;
      }
      .cardInfo {
        text-align: center;
        font-family: "Urbanist", sans-serif;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.36px;
      }
    }
  }
}

// @media (min-width: 769px) {
//   .whywe{
//     .text{
//       width: 100%;
//     }
//   }
// }

@media only screen and (max-width: 768px) {
  .whywe {
    padding: 50px 20px;
    .text {
      width: 100%;
      margin-bottom: 20%;
      .title{
        h1{
          font-size: 24px;
        }
      }
      .para {
        line-height: 27px;
        letter-spacing: 0.36px;
        font-size: 18px;
      }
    }
    .cardsDiv{
      padding: 0px 20px;
      .singleCard{
        height: 320px;
      }
    }
  }
}
