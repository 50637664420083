.main {
  padding: 20px;
  .cardsDiv {
    padding: 30px 30px 50px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 50px;
    background-color: #DBEAFF1A;
    .singleCard {
      width: 340px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .icon {
        width: 40px;
      }
      .title {
        h1 {
          color: #313c71;
          font-family: Urbanist;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.44px;
          //   padding: 0px 40px 0px 0px;
        }
        .gradient {
          margin: 10px 0px;
          width: 100%;
          height: 5px;
          //   background: linear-gradient(
          //     90deg,
          //     #000000 -2.39%,
          //     #313c71 -2.38%,
          //     rgba(227, 30, 36, 0) 83.32%
          //   );
          background: linear-gradient(
            90deg,
            #000000 -39.39%,
            #313c71e0 13.62%,
            #f3707466 55%,
            rgba(227, 30, 36, 0) 86.32%
          );
          border-radius: 5px;
          width: 140px;
        }
      }
      .detail {
        color: #303030;
        font-family: Urbanist;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.36px;
      }
    }
  }
}


@media only screen and (max-width: 768px) {
    .main{
        padding: 0px 20px;
        .cardsDiv{
            justify-content: center;
        }
    }
}