.main {
  height: 600px;
  background-image: url("../../assets//Banners//hosemedica.png");
  background-size: cover;
  background-position: bottom;
  padding-top: 5%;
  .links {
    display: flex;
    gap: 70px;
    background: linear-gradient(
      90deg,
      rgba(227, 30, 36, 0) -8.13%,
      #e31e24 29.51%,
      #e31e24 51%,
      #e31e24 70.75%,
      rgba(227, 30, 36, 0) 107.24%
    );
    justify-content: center;
    align-items: center;
    padding: 10px 0px;

    div {
      .link {
        color: #fff;
        cursor: pointer;
        font-family: Urbanist;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-decoration: none;
      }
    }
    .more {
      display: flex;
      gap: 7px;
      align-items: center;
      img {
        width: 12px;
      }
    }
  }

  .middleBox {
    height: 400px;
    width: 600px;
    // background-color: rgb(46 56 105 / 61%);
    margin: auto;
    margin-top: 5%;
    .title {
      font-family: "Urbanist", sans-serif;
      font-size: 94px;
      font-weight: 700;
      letter-spacing: 7.05px;
      background: linear-gradient(
        90deg,
        #e31f25 -14.44%,
        #fff 32.91%,
        #fff 64.36%,
        #e31f25 105.56%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
    }
    .caption {
      color: #fff;
      font-family: Urbanist;
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      letter-spacing: 0.72px;
    }
    .para {
      color: #fff;

      text-align: center;
      font-family: Urbanist;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px; /* 166.667% */
      letter-spacing: 0.45px;
      margin-top: 30px;
    }
    .inputBox {
      background-color: white;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #0e2a61;
      box-shadow: 0px 0px 26px 0px rgba(14, 42, 97, 0.3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5%;
      .left {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        img {
          width: 25px;
        }
        input {
          border: none;
          outline: none;
          width: 100%;
        }
      }
      .right {
        padding-left: 10px;
        display: flex;
        border-left: 1px solid black;
        height: 20px;
        align-items: center;
        img {
          width: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    height: fit-content;
    padding: 15% 20px;

    .links {
      display: none;
    }
    .middleBox {
      width: 100%;
      height: fit-content;
      .title {
        font-size: 40px;
        letter-spacing: 2.7px;
      }
      .caption {
        font-size: 14px;
        letter-spacing: 0.42px;
      }
      .para {
        font-size: 15px;
        font-weight: 400;
        line-height: 21px; /* 150% */
        letter-spacing: 0.35px;
      }
    }
  }
}
