.main {
  padding: 50px 0px;
  .text {
    margin: auto;
    margin-bottom: 3%;
    text-align: center;

    .title {
      width: fit-content;
      padding: 0px 10px 7px 10px;
      margin: 20px auto;
      color: #303030;

      h1 {
        text-align: center;
        font-family: Urbanist;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.76px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #000000 -2.39%,
          #313c71 -2.38%,
          rgba(227, 30, 36, 0) 83.32%
        );
        border-radius: 5px;
      }
    }
    .para {
      width: 900px;
      margin: auto;
      font-family: Urbanist;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.4px;
    }
  }

  // .outer{
  //   display: flex;
  //   .mapContent {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 100px;

  //     .mapImage {
  //       position: relative;
  //       width: 800px;
  //       .custom {
  //         background-color: white;
  //         color: black;
  //       }
  //     }
  //   }
  // }

  .mapContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;

    .stateDetails {
      width: 250px;
      .state {
        display: flex;
        align-items: center;
        gap: 15px;
        // padding: 12px 0px;

        .bulletpoint {
          width: 20px;
          height: 20px;
        }

        .stateName {
          color: #303030;
          font-family: Urbanist;
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0.44px;
        }
      }
      .straightline {
        width: 1px;
        height: 20px;
        padding: 0px 10px;
      }
    }
    .indiaMap {
      img {
        width: 500px;
      }
    }
  }
}

// @media (min-width: 769px) {
//   .main{
//     padding: 50px 30px;
//     .text{
//       .para{
//         width: 100%;
//       }
//     }
//     .mapContent{
//       gap: 0px;
//     }
//   }
// }

@media screen and (max-width: 768px) {
  .main {
    padding: 50px 15px;
    .text {
      .title {
        margin: 8px auto;
        h1 {
          font-size: 24px;
        }
      }
      .para {
        width: 100%;
        font-size: 18px;
      }
    }
    .mapContent {
      flex-direction: column;
      gap: 30px;

      .stateDetails {
        .state {
          gap: 25px;
          .stateName {
            font-size: 20px;
          }
        }
      }

      .indiaMap {
        img {
          width: 400px;
        }
      }
    }
  }
}
