.main {
  background: #bf1920;
  border-radius: 0px 150px;
  padding: 20px 0px;
  .text {
    width: 800px;
    margin: auto;
    margin-bottom: 3%;
    text-align: center;

    .title {
      width: fit-content;
      padding: 0px 10px 7px 10px;
      margin: 20px auto;

      h1 {
        color: #fff;
        text-align: center;
        font-family: Urbanist;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.76px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #000000 -2.39%,
          #313c71 -2.38%,
          rgba(227, 30, 36, 0) 83.32%
        );

        border-radius: 5px;
      }
    }
    .para {
      color: #fff;
      width: 500px;
      margin: auto;
      font-family: Urbanist;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.4px;
    }
  }
  .cardsDiv {
    width: 650px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 4%;

    .outer {
      position: relative;
      .comming_right {
        right: -20px;
        border-radius: 2px 0px 0px 2px;
        clip-path: polygon(100% 0, 92% 50%, 100% 100%, 0% 100%, 0% 0%);
        padding: 3px 25px 3px 5px;
      }
      .comming_left {
        left: -25px;
        clip-path: polygon(100% 0, 100% 100%, 0% 100%, 8% 50%, 0% 0%);
        border-radius: 0px 2px 2px 0px;
        padding: 3px 5px 3px 25px;
      }

      .comming {
        top: 10px;
        position: absolute;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        color: #e31e24;
        font-weight: 700;
        width: fit-content;
        background-color: #fff;
      }
    }

    .card1 {
      background: linear-gradient(#bf1920, #bf1920) padding-box,
        linear-gradient(30deg, white, transparent 70%) 0 100%/100% 100%
          no-repeat border-box;
      border-radius: 30px 0 0 0;
    }
    .card2 {
      background: linear-gradient(#bf1920, #bf1920) padding-box,
        linear-gradient(-30deg, white, transparent 70%) 0 100% / 100% 100%
          no-repeat border-box;
      border-radius: 0 30px 0 0;
    }
    .card3 {
      background: linear-gradient(#bf1920, #bf1920) padding-box,
        linear-gradient(30deg, white, transparent 70%) 0 100% / 100% 100%
          no-repeat border-box;
      border-radius: 0 0 0 30px;
    }
    .card4 {
      background: linear-gradient(#bf1920, #bf1920) padding-box,
        linear-gradient(-30deg, white, transparent 70%) 0 100% / 100% 100%
          no-repeat border-box;
      border-radius: 0 0 30px 0;
    }

    .singleCard {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 250px;
      padding: 40px 20px;
      border: 1.5px solid transparent;

      img {
        width: 45px;
      }
      p {
        color: #fff;
        font-family: Urbanist;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.4px;
      }
    }
  }

  .btn {
    border-radius: 8px;
    background: #313c71;
    padding: 12px 20px;
    color: #fff;
    font-family: Urbanist;
    letter-spacing: 0.4px;
    border: none;
    margin: auto;
    display: block;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
}

@media (min-width: 769px) {
  .main{
    .text{
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    border-radius: 0px 100px;
    padding: 50px 0px;
    .text {
      width: 80%;
      margin-bottom: 7%;
      .title {
        margin: 10px auto;
        h1 {
          font-size: 24px;
        }
      }
      .para {
        width: 100%;
        font-size: 18px;
      }
    }
    .cardsDiv {
      flex-direction: column;
      width: 100%;
      margin-bottom: 10%;
    }
  }
}
