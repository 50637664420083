.main {
  border-radius: 0px 150px;
  background: linear-gradient(180deg, #e31e24 0%, #9f151d 146.17%);
  padding: 20px 0px 70px 0px;
  .text {
    width: 800px;
    margin: auto;
    margin-bottom: 3%;
    text-align: center;

    .title {
      width: fit-content;
      padding: 0px 10px 7px 10px;
      margin: 20px auto;

      h1 {
        color: #fff;
        text-align: center;
        font-family: Urbanist;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.76px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #000000 -2.39%,
          #313c71 -2.38%,
          rgba(227, 30, 36, 0) 83.32%
        );
        border-radius: 5px;
      }
    }
  }

  .textBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 120px;
    padding: 0px 40px;

    .box {
      width: 400px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      .box_title {
        font-family: Urbanist;
        font-size: 28px;
        font-weight: 700;
        text-decoration-line: underline;
      }
      .para {
        text-align: center;
        font-family: Urbanist;
        font-size: 20px;
        line-height: 30px;
      }
      .downloadBtn {
        border-radius: 8px;
        background: #313c71;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px 30px;
        cursor: pointer;
        width: 220px;
        justify-content: center;
        img {
          width: 15px;
        }
        p {
          font-family: Urbanist;
          font-size: 18px;
          font-weight: 400;
        }

        &:hover{
            background: #384276;  
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
    .main{
        border-radius: 0px 100px;
        .text{
            width: 100%;
            .title{
                h1{
                    font-size: 24px;
                }
            }
        }
        .textBox{
            flex-direction: column;
            gap: 80px;
            .box{
                width: 300px;
                .box_title{
                    font-size: 22px;
                }
                .para{
                    font-size: 18px;
                }
            }
        }
    }
}