.main {
  padding: 50px 0px;
  .text {
    margin: auto;
    margin-bottom: 3%;
    text-align: center;

    .title {
      width: fit-content;
      padding: 0px 10px 7px 10px;
      margin: 20px auto;
      color: #303030;

      h1 {
        text-align: center;
        font-family: Urbanist;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.76px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #000000 -2.39%,
          #313c71 -2.38%,
          rgba(227, 30, 36, 0) 83.32%
        );
        border-radius: 5px;
      }
    }
    .para {
      width: 500px;
      margin: auto;
      font-family: Urbanist;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.4px;
    }
  }

  .formDiv {
    width: 700px;
    margin: auto;
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 18px 0px;

      .inputDiv {
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 5px;
        border: 0.5px solid rgba(49, 60, 113, 0.6);
        padding: 10px 7px;
        width: 325px;

        .inputIcon {
          width: 25px;
        }
        input,
        textarea {
          border: none;
          background-color: transparent;
          outline: none;
          font-family: Urbanist;
          font-size: 18px;
          color: #081c4380;
          width: 100%;
          resize: none;
        }
      }

      .inputDiv:nth-child(5) {
        width: 100%;
      }
      .inputDiv:nth-child(6) {
        width: 100%;
        display: flex;
        align-items: flex-start;
      }

      .sbtBtn {
        color: #fff;
        border: none;
        border-radius: 8px;
        background: #313c71;
        padding: 18px 0px;
        width: 100%;
        font-family: Helvetica;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}


@media screen and (max-width: 768px) {
    .main{
        padding: 50px 20px;
        .text{
            .title{
                h1{
                    font-size: 24px;
                }
            }
            .para{
                width: 100%;
                font-size: 18px;
                line-height: 27px;
            }
        }
        .formDiv{
            margin-top: 10%;
            width: 100%;

            form{
                padding: 0px 10px;
                .inputDiv{
                    width: 100%;
                }
            }
        }
    }
}