.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 30px;
  box-shadow: 0px 11px 28px 0px rgba(0, 0, 0, 0.12);

  .left {
    img {
      width: 200px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 25px;
    .contactDiv {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 40px;
        cursor: pointer;
      }
      .detailOuter {
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        & > :nth-child(1) {
          color: rgba(48, 48, 48, 0.74);
        }
        & > :nth-child(2) {
          text-decoration: none;
          color: #303030;
          font-weight: 500;
        }
        .mailId {
          text-decoration: underline;
        }
      }
    }
    .socialsDiv {
      p {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: rgba(48, 48, 48, 0.74);
      }
      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 5px 2px 0px 2px;
        .icon {
          width: 26px;
          cursor: pointer;
        }
      }
    }
  }
  .hamburger {
    display: none;
  }
 
}

// @media (min-width: 769px) {
//   .navbar{
//     .left{
//       img{
//         width: 150px;
//       }
//     }
//     .right{
//       gap: 15px;

//       .contactDiv{
//         img{
//           width: 33px;
//         }
//         .detailOuter{
//           font-size: 12px;
//         }
//       }
//       .socialsDiv{
//         .icons{
//           .icon{
//             width: 23px;
//           }
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 10px 15px;
    .left {
      img {
        width: 150px;
      }
    }
    .right {
      display: none;
    }
    .hamburger {
      display: block;
      img {
        border: 1px solid #f6f6f6;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 7px;
        width: 40px;
        cursor: pointer;
      }
    }

  }
}
