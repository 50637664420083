.main {
  padding: 20px;
  margin: 0px 20px 50px 20px;
  border-radius: 8px;
  background: rgba(219, 234, 255, 0.2);
  .actBtns {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;
    button {
      border-radius: 7px;
      padding: 10px 20px;
      font-family: Urbanist;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      letter-spacing: 0.4px;
    }
    .download {
      color: #313c71;
      background: #dbeaff;
      border: 1px solid rgba(49, 60, 113, 0.5);
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 15px;
      }
    }
    .contact {
      color: #fff;
      background: #313c71;
      border: 1px solid #313c71;
    }
  }

  .cardsDiv {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .singleCard {
      border: 0.5px solid rgba(49, 60, 113, 0.4);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      padding: 0px 30px;
      background-color: #fff;

      .cardTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 7px;
        background: #fff;
        padding: 15px 0px;
        height: 40px;

        .title {
          color: #303030;
          text-align: center;
          font-family: Urbanist;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.44px;
        }
        .addIcon {
          width: 30px;
          cursor: pointer;
          transition: 1s ease-out;

          &:hover {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          }
        }
        .commingsoon {
          width: 120px;
          margin-top: -47px;
          margin-right: -15px;
        }
      }
      .cardContent {
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.04);
        padding: 15px 0px;

        .contentTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            gap: 12px;

            span {
              font-size: 18px;
              font-family: Helvetica;
            }

            .numDiv {
              padding: 7px 12px;
              border: 0.5px solid #0e2a61;
              border-radius: 6px;
              display: flex;
              align-items: center;
              gap: 15px;

              .num {
                padding-right: 20px;
                border-right: 1px solid #111;
              }
              img {
                width: 15px;
                cursor: pointer;
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 9px;
            border-radius: 5px;
            background-color: #fff;
            border: 1px solid rgba(14, 42, 97, 0.3);

            img {
              width: 23px;
            }
            input {
              border: none;
              outline: none;
              font-size: 18px;
              color: rgba(14, 42, 95, 0.2);
            }
          }
        }
        .contentHeader,
        .contentBody .contentBodyDetail {
          background-color: #22354f;
          padding: 7px 20px;
          color: #fff;
          border-radius: 7px;
          margin: 10px 0px;
          display: grid;
          align-items: center;
          grid-template-columns: 8% 18% 19% 19% 19% 17%;
          font-family: Urbanist;
          font-size: 20px;
          font-weight: 500;
        }
        .contentBody {
          background: rgba(219, 234, 255, 0.4);
          color: #535353;
          border-radius: 7px;
          .contentBodyDetail {
            background: transparent;
            color: #535353;
            font-weight: 700;
            font-size: 18px;
            padding: 15px 20px;

            .moreDiv {
              display: flex;
              align-items: center;
              gap: 10px;
              justify-content: flex-end;
              cursor: pointer;
              .contentMore {
                color: #313c71;
                font-size: 20px;
                font-weight: 400;
              }
              .outlineArrow {
                width: 10px;
                transform: rotate(360deg);
              }
              .closeComp {
                width: 10px;
                transform: rotate(180deg);
              }
            }
          }

          .contentComp {
            padding: 10px 20px;
            margin-left: 8%;
            font-family: Urbanist;
            font-size: 18px;
            p:nth-child(1) {
              font-weight: 600;
            }
            p:nth-child(2) {
              width: 800px;
              line-height: 27px;
              padding: 8px 0px;
            }
          }
        }
      }
      .mobilecardContent {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 10px;
    margin: 0px 14px 50px 14px;
    .actBtns {
      justify-content: space-between;
      button {
        padding: 10px 15px;
      }
    }
    .cardsDiv {
      .singleCard {
        padding: 0px 20px;
        .cardContent {
          display: none;
        }
        .mobilecardContent {
          display: block;
          background: #fff;
          border-top: 1px solid rgba(0, 0, 0, 0.04);
          padding: 0px;

          .contentTop {
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            
            .right {
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 9px;
              border-radius: 5px;
              background-color: #fff;
              border: 1px solid rgba(14, 42, 97, 0.3);

              img {
                width: 23px;
              }
              input {
                border: none;
                outline: none;
                font-size: 18px;
                color: rgba(14, 42, 95, 0.2);
              }
            }
          }

          .rangename {
            color: #000;
            font-family: Urbanist;
            font-size: 18px;
            font-weight: 500;
            padding: 14px 0px 7px 0px;
            letter-spacing: 0.32px;
          }

          .contentBody {
            background: rgba(219, 234, 255, 0.4);
            border-radius: 7px;
            margin: 10px 0px;
            .contentBodyDetail {
              background: transparent;
              padding: 15px 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                color: #535353;
                font-family: Urbanist;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }

              .outlineArrow {
                width: 25px;
                transform: rotate(360deg);
              }
              .closeComp {
                width: 25px;
                transform: rotate(180deg);
              }
            }

            .contentComp {
              padding: 10px 20px;
              font-family: Urbanist;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              gap: 15px;
              p:nth-child(1) {
                font-weight: 600;
                font-size: 15px;
                color: #000;
              }
              p:nth-child(2) {
                padding: 8px 0px;
                font-size: 17px;
                color: #535353;
              }
            }
          }
        }
      }
    }
  }
}
