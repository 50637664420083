.main {
  border-radius: 150px 0px;
  background: linear-gradient(180deg, #313c71 0%, #22354f 117.09%);
  padding: 20px 0px 70px 0px;
  .text {
    width: 800px;
    margin: auto;
    margin-bottom: 3%;
    text-align: center;

    .title {
      width: fit-content;
      padding: 0px 10px 7px 10px;
      margin: 20px auto;

      h1 {
        color: #fff;
        text-align: center;
        font-family: Urbanist;
        font-size: 38px;
        font-weight: 700;
        letter-spacing: 0.76px;
      }
      .gradient {
        margin: 10px 0px;
        width: 100%;
        height: 5px;
        background: linear-gradient(
          90deg,
          #e31e24 -2.39%,
          rgba(49, 60, 113, 0) 83.32%
        );
        border-radius: 5px;
      }
    }
  }
  .cardsDiv {
    display: flex;
    justify-content: center;
    gap: 40px;
    .outerCard {
      // background: linear-gradient(
      //     151.98deg,
      //     rgba(255, 255, 255, 0) 3.24%,
      //     #e31e24 104.26%
      //   ),
      //   linear-gradient(0deg, #ffffff, #ffffff);

      background: linear-gradient(
          151.98deg,
          rgba(255, 255, 255, 0) 10%,
          #e23b40 104.26%
        ),
        linear-gradient(0deg, #ffffff, #ffffff);
      background-origin: border-box, content-box;
      background-clip: border-box, content-box;

      padding: 10px;
      border-radius: 0px 0px 50px 0px;
      .singleCard {
        width: min-content;
        background: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 0px 0px 40px 0px;

        img {
          width: 300px ;
        }
        .cardTitle {
          background: linear-gradient(
            180deg,
            rgba(49, 60, 113, 0.7) 0%,
            rgba(227, 30, 36, 0.3) 100%
          );
          font-family: Urbanist;
          font-size: 20px;
          font-weight: 700;
          color: #fff;
          padding: 10px 0px;
          text-align: center;
        }
        .detail {
          color: #303030;
          font-family: Urbanist;
          font-size: 18px;
          font-weight: 500;
          padding: 10px 20px;
          letter-spacing: 0.36px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          line-height: 25px;
          span {
            color: #313c71;
            font-size: 18px;
            padding: 10px 0px;
            font-weight: 600;
            cursor: pointer;
            width: fit-content;
          }
        }
      }
    }
  }
}



@media (min-width: 769px) {
  .main{
    .text{
      width: 100%;
    }
    .cardsDiv{
      gap: 30px;
      // flex-direction: column;
      // align-items: center;
      flex-wrap: wrap;
    }
  }
  
}

@media screen and (max-width: 768px) {
  .main {
    border-radius: 100px 0px;
    .text {
      width: auto;
      .title {
        h1 {
          font-size: 24px;
        }
      }
    }
    .cardsDiv {
      flex-direction: column;
      align-items: center;

      .outerCard {
        border-radius: 50px 0px 50px 0px;
        .singleCard {
          border-radius: 40px 0px 40px 0px;
          img {
            border-radius: 40px 0px 0px 0px;
            width: 250px;
          }
          .cardTitle {
            font-size: 16px;
          }
          .detail {
            font-size: 16px;
            padding: 10px 15px;

            span {
              font-size: 15px;
              padding: 5px 0px;
            }
          }
        }
      }
    }
  }
}
